<template>
  <div class="info">
    <top-bar :title="!submitData.id?'新增':edit?'修改':'查看'" :left="true"></top-bar>
    <!-- 宣传类型 -->
    <van-popup v-model="propagandaTypeShow" position="bottom" >
      <van-cascader v-model="submitData.propagandaType" title="请选择" :options="selectData.propagandaTypeList"
                    @close="propagandaTypeShow = false" @finish="propagandaTypeFinish" :field-names="fieldNames"/>
    </van-popup>
    <!-- 宣传等级 -->
    <van-popup v-model="propagandaLevelShow" position="bottom" >
      <van-cascader v-model="submitData.propagandaLevel" title="请选择" :options="selectData.propagandaLevelList"
                    @close="propagandaLevelShow = false" @finish="propagandaLevelFinish" :field-names="fieldNames"/>
    </van-popup>
    <!-- 刊登日期 -->
    <van-popup v-model="postDateShow" position="bottom" >
      <van-datetime-picker v-model="submitData.postDate" type="date" title="请选择" :formatter="formatDate"
                            @cancel="postDateShow = false" @confirm="postDateConfirm" :max-date="maxDate" />
    </van-popup>

        <!-- 宣传分类 -->
    <van-popup v-model="typeShow" position="bottom" >
      <!-- <van-cascader v-model="submitData.type" title="请选择" :options="selectData.typeList"
              @close="typeShow = false" @finish="typeConfirm" :field-names="fieldNames"/> -->
      <div class="typeCheck">
        <span @click="typeShow = false"></span>
        <span>请选择</span>
        <span @click="typeShow = false">确定</span>
      </div>
      <van-checkbox-group class="typeCheckBox" @change="change" v-model="submitData.type">
        <van-cell-group v-for="(item, index) in selectData.typeList" :key="index">
          <van-cell  :title="item.label"  @click="toggle(index)">
            <template #icon>
              <van-checkbox :name="item" ref="checkboxes"/><van-icon v-if="item.children !== null" :name="childrenVisible ?'arrow-up':'arrow-down'" @click.stop="childrenVisible = !childrenVisible"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">宣传录用信息</span>
        </template>
      </van-cell>
      <van-cell title="标题">
        <van-field clearable clear-trigger="always" v-model="submitData.title" placeholder="请输入" :readonly="!edit"/>
      </van-cell>
      <van-cell title="宣传类型" :value="selectMsg.propagandaTypeStr" @click="propagandaTypeShow = edit"
                :value-class="{'value-common':selectMsg.propagandaTypeStr=='请选择'}" :is-link="edit" />
      <van-cell title="宣传级别" :value="selectMsg.propagandaLevelStr" @click="propagandaLevelShow = edit"
                :value-class="{'value-common':selectMsg.propagandaLevelStr=='请选择'}" :is-link="edit"  />
      <van-cell title="媒体">
        <van-field clearable clear-trigger="always" v-model="submitData.categroy" placeholder="请输入" :readonly="!edit"/>
      </van-cell>
      <van-cell title="阅读量">
        <van-field clearable clear-trigger="always" type="number" v-model="submitData.readingQuantity" placeholder="请输入" :readonly="!edit"/>
      </van-cell>
      <van-cell title="分数">
        <van-field clearable clear-trigger="always" type="number" v-model="submitData.score" placeholder="请输入" :readonly="!edit"/>
      </van-cell>
      <van-cell title="宣传分类" :value="selectMsg.typeStr" @click="typeShow = edit"
                :value-class="{'value-common':selectMsg.typeStr=='请选择'}" :is-link="edit" />
      <van-cell title="刊登日期" :value="selectMsg.postDate" @click="postDateShow = edit"
                :value-class="{'value-common':selectMsg.postDate=='请选择'}" :is-link="edit" />
      <van-cell title="链接">
        <van-field clearable clear-trigger="always" v-model="submitData.url" placeholder="请输入" :readonly="!edit"/>
      </van-cell>
      <van-row v-if="edit||fileList.length!==0">
        <van-col :span="24">
          <van-uploader v-model="fileList" @delete="uploaddel" :after-read="uploadRead"  :deletable="edit" :show-upload="edit"/>
        </van-col>
      </van-row>
    </van-cell-group>
    <van-row class="btns">
      <van-col :span="24" v-if="edit">
        <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
      </van-col>
      <van-row gutter="10" v-else>
        <van-col :span="12">
          <van-button plain type="danger" size="large" round @click="deleteHandle" style="background-color:transparent">删除</van-button>
        </van-col>
        <van-col :span="12">
          <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
        </van-col>
      </van-row>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDate} from "@/utils/utils"
import {getImageStream} from '@/utils/index'
import {getDictTree} from "@/utils/common";
export default {
  components: {
    topBar
  },
  data () {
    return {
      i: '',
      edit: false,
      file:{},
      detailData: {},
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      placeListVisible: false,
      postDateShow: false,
      buildingTypeShow: false,
      buildingShow: false,
      publicBuildingShow: false,
      subareaShow: false,
      propagandaTypeShow: false,
      propagandaLevelShow: false,
      typeShow: false,
      problemShow: true,
      problemSubmit: false,
      problemTypeShow: false,
      selectMsg: {
        propagandaTypeStr: '请选择',
        propagandaLevelStr: '请选择',
        postDate: '请选择',
        typeStr: '请选择'
      },
      submitData: {
        id: '',
        title: '',
        categroy: '',
        postDate: '',
        url: '',
        propagandaType: '',
        propagandaLevel: '',
        newFileMessages: [],
        deleteFiles: [],
        orgId: '',
        readingQuantity: '',
        score: '',
        type: []
      },
      selectData: {
        propagandaTypeList: [],
        propagandaLevelList: [],
        typeList: [],
      },
      fileList: [],
      maxDate: new Date(),
    }
  },
  watch: {
    'submitData.postDate' (val) {
      if (val == null && this.selectMsg.postDate !== '请选择') {
        this.submitData.postDate = this.selectMsg.postDate
      }
    }
  },
  methods: {
    change(val) {
      this.selectMsg.typeStr = val.map((item,index) => item.label).join(',')
      this.typeShow = true
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.submitData.newFileMessages.splice(i, 1)
      if (file.id !== 0) {
        this.submitData.deleteFiles.push(file.id)
      }
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      })
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file)
      formdata.append("path", 'files/app/propaganda/'+ this.submitData.orgId +'/temporary')
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
      }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
        if (data.code == 0) {
          let fileMessage = data.fileMessage
          fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
          this.submitData.newFileMessages.push(fileMessage)
          return true
        } else {
          this.$toast.fail(data.msg)
        }
      })
      })
    },
    // 初始化
    init () {
      this.getDictTypeList('propagandaType')
      this.getDictTypeList('problemType')
      this.getDictTypeList('propagandaLevel')
      this.getDictTypeList('propagandaClassType')
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit =  true
      }
    },
    // 获取字典
    getDictTypeList (code) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: code,
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (code === 'propagandaType') {
            this.selectData.propagandaTypeList = data.dicts
          } else if (code === 'propagandaLevel') {
            this.selectData.propagandaLevelList = data.dicts
          } else if (code === 'propagandaClassType') {
            this.selectData.typeList = data.dicts
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取详情
    getInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/propaganda/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.submitData.id
        })
      }).then(({data})=> {
        if (data && data.code === 0) {
          this.submitData.title = data.appPropaganda.title
          this.selectMsg.propagandaTypeStr = data.appPropaganda.typeStr
          this.submitData.propagandaType = data.appPropaganda.type
          this.selectMsg.propagandaLevelStr = data.appPropaganda.levelStr
          this.submitData.propagandaLevel = data.appPropaganda.level
          this.submitData.categroy = data.appPropaganda.categroy
          this.selectMsg.postDate = data.appPropaganda.postDate
          this.submitData.postDate = data.appPropaganda.postDate
          this.submitData.url = data.appPropaganda.url
          this.submitData.readingQuantity = data.appPropaganda.readingQuantity || ''
          this.submitData.score = data.appPropaganda.score || ''
          // this.submitData.type = data.appPropaganda.propagandaType || ''
          this.selectMsg.typeStr = data.appPropaganda.propagandaTypeStr || '请选择'
          let files = data.appPropaganda.fileMessages
          let urls = []

           data.appPropaganda.propagandaType.split(',').forEach(item => {
             this.selectData.typeList.forEach(val => {
               if (val.value == item) {
                 this.submitData.type.push(val)
               }
             })
           })
           console.log(this.submitData.type);
          for (let i in files) {
            let file = files[i]
            let u
            u = getImageStream(files[i].relativePath)
            let url = {
              uid: urls.length,
              id: file.id,
              status: 'done',//uploading上传中，done上传完成，error上传失败
              fileType: file.type,
              url: u,
              realUrl: file.objectUrl.replace(/\\/g, "/")
            }
            urls.push(url);
          }
          this.fileList = urls
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/propaganda/delete'),
          method: 'post',
          params: this.$http.adornParams({
            ids: parseInt(this.submitData.id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 提交
    submit () {
      if (this.submitData.title === '') {return this.$toast.fail('失败:请先输入标题');}
      if (this.submitData.propagandaType === '') {return this.$toast.fail('失败:宣传类型不能为空');}
      if (this.submitData.propagandaLevel === '') {return this.$toast.fail('失败:宣传级别不能为空');}
      if (this.submitData.categroy === '') {return this.$toast.fail('失败:媒体不能为空');}
      if (this.submitData.postDate === '') {return this.$toast.fail('失败:刊登日期不能为空');}
      if (this.submitData.url === '') {return this.$toast.fail('失败:链接不能为空');}
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/propaganda/${this.submitData.id?'update':'save'}`),
        method: 'post',
        data : {
          id: this.submitData.id,
          orgId: this.submitData.orgId,
          //标题
          title: this.submitData.title,
          //媒体
          categroy: this.submitData.categroy,
          //刊登日期
          postDate: this.submitData.postDate,
          //链接
          url: this.submitData.url,
          //宣传类型
          type: this.submitData.propagandaType,
          //宣传级别
          level: this.submitData.propagandaLevel,
          //修改图片组
          newFileMessages: this.submitData.newFileMessages,
          //删除图片的id
          deleteFiles: this.submitData.deleteFiles,
          // 阅读量
          readingQuantity: this.submitData.readingQuantity,
          // 分数
          score: this.submitData.score,
          // 宣传分类
          propagandaType: this.submitData.type.map(item => item.value).join(','),
        }
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success ({
            message: `${this.submitData.id?'修改成功':'新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 宣传类型选择
    propagandaTypeFinish (value) {
      if (value) {
        this.selectMsg.propagandaTypeStr = value.selectedOptions[0].label
        this.submitData.propagandaType = value.value
      }
      this.propagandaTypeShow = false
    },
    // 日期选项格式化
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    postDateConfirm (value) {
      if (value) {
        this.selectMsg.postDate = formatterDate(value)
        this.submitData.postDate = formatterDate(value)
      }
      this.postDateShow = false
    },
    // 宣传等级选择
    propagandaLevelFinish(value) {
      if (value) {
        this.selectMsg.propagandaLevelStr = value.selectedOptions[0].label
        this.submitData.propagandaLevel = value.value
      }
      this.propagandaLevelShow = false
    }
  },
  created () {
    this.submitData.id = this.$route.query.id || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.init()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style lang="scss" scoped>
.typeCheck {
  display: flex;
  height: 150px;
  line-height: 115px;
  padding-left: 40px;
  span {
    display: block;
    flex: 1;
    font-size: 30px;
  }
  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }
  span:nth-child(3) {
    margin-right: -187px;
    font-size: 28px;
    color: #576b95;
  }
}
.typeCheckBox .van-cell__title {
  margin-left: 20px;
}
.typeCheckBox .van-icon {
  position: absolute;
  right: 40px;
  bottom: 45%;
}
.children {
  margin-left: 50px;
}
.children .van-cell__value {
  margin-left: 10px;
}
</style>